@import 'styles/core/typography';
@import 'styles/core/buttons';

.iconSize {
  width: var(--space-size-2);
  height: var(--space-size-2);
}

.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 3;

  &.wrapperOpened {
    background: linear-gradient(
      0deg,
      rgba(15, 19, 21, 0.8) 0%,
      rgba(15, 19, 21, 0.8) 69%,
      rgba(15, 19, 21, 1) 69%,
      rgba(15, 19, 21, 1) 100%
    );
    height: calc(100vh - 6.2rem);
    position: fixed;
    left: 0;
    top: 6.2rem;
    right: 0;
    bottom: 0;
    z-index: 5;

    @media screen and (min-width: $breakpoint-md) {
      top: 5.8rem;
      height: calc(100vh - 5.8rem);
    }
  }
}

.sectionList {
  display: grid;
  grid-column: 1/-1;

  @media screen and (min-width: $breakpoint-md) {
    &.split {
      grid-template-columns: 65% auto;
    }
    grid-column: 2;
  }

  &.searched {
    display: block;
  }

  margin: var(--space-size-3) 0;
  overflow-y: auto;

  .mostSearchedLabel {
    margin-bottom: var(--space-size-3);
    @include label-m-bold;
    text-transform: uppercase;
    color: var(--color-on-bg-secondary);
  }

  .chosenFromSnaiContainer {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    @media screen and (min-width: $breakpoint-md) {
      margin-top: 0;
    }

    .chosenFromSnai {
      @include label-m-bold;
      text-transform: uppercase;
      color: var(--color-on-bg-secondary);
      margin-bottom: var(--space-size-3);
    }

    .slotChosenFromSnaiCard {
      width: 19.5rem;
      height: 30rem;
    }

    .cardsContainer {
      display: grid;
      grid-template-columns: repeat(3, 0fr);
      grid-template-rows: repeat(2, auto);
      gap: var(--space-size-3);

      [class*='CardImageContainer_container'] {
        width: 8.5rem;
        height: 10.6rem;
        min-height: unset;
        margin-bottom: 0;
        padding-bottom: 0;

        [class*='CardHoverMask_link'] {
          width: 5.8rem;
          height: 3rem;
          padding: var(--space-size-1) 1.2rem;
          font-size: 1.2rem;
        }
      }
    }
  }

  & .searchedItem {
    transition: all 0.3s ease-in-out;
    margin-top: var(--space-size-3);
    margin-right: var(--space-size-1);
    margin-bottom: var(--space-size-3);
    cursor: pointer;

    p {
      display: flex;
      flex-direction: column;
      label {
        cursor: pointer;
      }

      :nth-child(1) {
        @include body-medium;
      }
      :nth-child(2) {
        @include body-xs-bold;
        color: var(--color-on-bg-secondary);
      }
    }
  }
}

.opened {
  overflow-y: visible;
  min-height: 50rem;
  transition: height, overflow-y, min-height;
  transition-duration: 0.08s;
  height: 100vh;
  display: grid;
  grid-template-columns: 80% 20%;
  grid-template-rows: 7.5%;
  z-index: 3;
  padding: var(--space-size-3);
  padding-top: 0;
  position: fixed;
  height: calc(100vh - 6rem);
  width: 100%;
  background-color: var(--color-bg);

  & .label {
    margin-left: var(--space-size-1);
  }

  @media screen and (min-width: $breakpoint-md) {
    grid-template-columns: 15% auto 10%;
    grid-template-rows: 9.8rem;
    height: fit-content;
    position: absolute;
    padding: var(--space-size-5);
    padding-bottom: 0;
    padding-top: 0;
  }

  .headingTitle {
    display: none;
    padding-top: 2.8rem;
    text-transform: capitalize;

    @media screen and (min-width: $breakpoint-md) {
      display: block;
    }
    @include heading-l-medium;
    color: var(--color-on-bg-secondary);
  }

  .form {
    display: grid;
    align-items: center;
  }
}

.resetResearch {
  @include label-xs-bold;
  color: var(--color-on-bg-secondary);
  text-transform: uppercase;
  cursor: pointer;
}

.sectionActionable {
  @include label-s-medium;
  color: var(--color-on-bg-primary);
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-self: flex-end;

  span {
    margin-left: 1.2rem;
    display: none;

    @media screen and (min-width: $breakpoint-md) {
      display: block;
    }
  }

  svg {
    width: var(--space-size-2);
    height: var(--space-size-2);
  }

  @media screen and (min-width: $breakpoint-sm) {
    @include label-m-medium;
  }
  @media screen and (min-width: $breakpoint-lg) {
    @include label-l-medium;
  }
}

.closed {
  height: 0rem;
  overflow-y: hidden;
  min-height: 0rem;
  transition: all 0.2s ease-out, padding 0s;
  * {
    opacity: 0;
  }
}

.skeletonContainer {
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 1;

  @media screen and (min-width: $breakpoint-md) {
    grid-column: 2 / span 1;
  }

  [class*='SkeletonArea'] {
    margin-bottom: var(--space-size-1);
    border-radius: 0.4rem;
    width: 70%;
  }
}

.researchButton {
  @include button-secondary-small;
  margin: 0 0 0 var(--space-size-2);
}
